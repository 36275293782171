import React, { useMemo } from "react";
import styles from "../../style.module.scss";
import AudioMessage from "../../../AudioMessage/AudioMessage";
import ImageMessage from "../ImageMessage";
import VideoMessage from "../VideoMessage";
import LinkMessage from "../LinkMessage";
import FileMessage from "../FileMessage";
import ContactMessage from "../ContactMessage";
import MapMessage from "../MapMessage";

const Renderers = {
  audio: AudioMessage,
  image: ImageMessage,
  video: VideoMessage,
  link: LinkMessage,
  file: FileMessage,
  contact: ContactMessage,
  map: MapMessage,
};

const TextMessage = ({ content, message, flatMessages }) => {
  const replyMsg = flatMessages?.find(
    (item) => item?.ID === message?.ReplyTo?.ReplyToMsgID
  );

  const URL = replyMsg?.Media?.Webpage?.URL;
  const isAudio = replyMsg?.Media?.Document?.MimeType?.includes("audio");
  const Image = replyMsg?.Media?.Photo;
  const isVideo = replyMsg?.Media?.Document?.MimeType?.includes("video");
  const isContact = replyMsg?.Media?.PhoneNumber;
  const isMap = replyMsg?.Media?.Geo;
  const isFile = replyMsg?.Media?.Document?.Attributes?.find((item) =>
    item.hasOwnProperty("FileName")
  )?.FileName;

  const messageType = useMemo(() => {
    const messageTypesMap = {
      audio: isAudio,
      video: isVideo,
      image: Image,
      link: URL,
      file: isFile,
      contact: isContact,
      map: isMap,
    };

    return (
      Object.keys(messageTypesMap).find((key) => messageTypesMap[key]) || null
    );
  }, [isAudio, isVideo, Image, URL, isFile, isContact, isMap]);

  const ReplyMessages = Renderers[messageType] || null;

  return (
    <>
      <div className={styles.body}>
        {message?.ReplyTo?.ReplyToMsgID && (
          <span className={styles.reply}>
            {ReplyMessages && (
              <ReplyMessages
                message={replyMsg}
                videoWidth="100%"
                videoHeight="100%"
                url={`https://test.cdn.tripoasia.com/${replyMsg?.Media?.links?.[0]}`}
              />
            )}
            {replyMsg?.Message}
          </span>
        )}
        <p className={styles.content}>{content}</p>
      </div>
    </>
  );
};

export default TextMessage;
