import { Box } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player";
import styles from "../../style.module.scss";

const VideoMessage = ({
  message,
  videoWidth = "640px",
  videoHeight = "360px",
}) => {
  return (
    <>
      <Box className={styles.video}>
        <ReactPlayer
          height={videoHeight}
          width={videoWidth}
          style={{
            minWidth: "300px",
            minHeight: "200px",
          }}
          controls
          url={`https://test.cdn.tripoasia.com/${message?.Media?.links?.[0]}`}
        />
      </Box>
    </>
  );
};

export default VideoMessage;
