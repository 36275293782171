import styles from "./style.module.scss";

import { ReactComponent as TALogoIcon } from "../../assets/icons/ta_logo.svg";
import { ReactComponent as ArrivalFlightIcon } from "../../assets/icons/arrival_plane.svg";
import { ReactComponent as DepartureFlightIcon } from "../../assets/icons/departure_plane.svg";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import classNames from "classnames";

import moment from "moment";
import { Box, Typography } from "@mui/material";
import { useMemo } from "react";

const calculateDuration = (val) => {
  const removedPt = val?.replace("PT", "");
  const splittedHrs = removedPt?.split("H");
  const hours = splittedHrs?.[0];
  const splittedMinutes = splittedHrs?.[1]?.split("M");
  const minutes = splittedMinutes?.[0];

  return [hours, minutes].filter((item) => !!item).join(":");
};

const _calculateDuration = (prev_itin_route_time, this_route_time) => {
  let a = moment(prev_itin_route_time);
  let b = moment(this_route_time);

  const total_hours = b.diff(a, "hours");
  const total_mins = b.diff(a, "minutes");

  return `${total_hours}h ${total_mins - total_hours * 60}m`;
};

const calculateLayover = (prev_itin_route_time, this_route_time) => {
  let a = moment(prev_itin_route_time);
  let b = moment(this_route_time);

  const total_hours = b.diff(a, "hours");
  const total_mins = b.diff(a, "minutes");

  return `${total_hours}h ${total_mins - total_hours * 60}m`;
};

//https://content.airhex.com/content/logos/airlines_SU_700_100_r.png?proportions=keep
const TicketVariant = ({
  data,
  onClick,
  isActive,
  onlyRead = false,
  isRoundTrip,
}) => {
  const layovers = useMemo(() => {
    const _lays = [];
    const segments = [];

    data.itineraries.forEach((itin, i) =>
      itin.segments.forEach((segm, s) => {
        segments.push(segm);
      })
    );

    segments.forEach((segm, idx) => {
      if (idx === 0) return;
      const calculatedTime = calculateLayover(
        segments[idx - 1].arrival.at,
        segm.departure.at
      );

      const hours = Number(calculatedTime.split("h")[0]);

      if (hours > 24) _lays.push("roundtrip");
      else _lays.push(calculatedTime);
    });

    if (isRoundTrip) _lays[Math.floor(_lays.length / 2)] = "roundtrip";

    return _lays;
  }, [data, isRoundTrip]);

  return (
    <div
      className={classNames(styles.ticket, {
        [styles.active]: isActive,
        [styles.onlyRead]: onlyRead,
      })}
    >
      {data.itineraries.map((itinerary, it_idx) =>
        itinerary?.segments?.map((route, r) => {
          const itins = [...data.itineraries];
          const initSegmentCount =
            itins
              .splice(0, it_idx)
              .map((itin) => itin.segments.length)
              .reduce((prev, curr) => {
                return prev + curr;
              }, 0) + r;

          const layover =
            it_idx >= 1 ? layovers[initSegmentCount] : layovers[r];

          return (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <div
                key={r}
                onClick={() => !onlyRead && onClick(data)}
                className={classNames(styles.route)}
              >
                <div className={styles.left}>
                  <img
                    // src={`https://content.airhex.com/content/logos/airlines_${route.carrierCode}_90_30_r.png?proportions=keep`}
                    src={`http://pics.avs.io/100/25/${route?.carrierCode}.png`}
                    alt={route.carrierCode}
                  />
                  <h3>{moment(route.departure.at).format("HH:mm")}</h3>
                  <p>{route.departure.iataCode}</p>
                  <p>{moment(route.departure.at).format("MMMM DD")}</p>
                </div>
                <div className={styles.middle}>
                  <div className={styles.date}>
                    <DepartureFlightIcon />
                    <>
                      {itinerary?.duration
                        ?.replace("PT", "")
                        .replace("H", "h ")
                        .replace("M", "m")}
                    </>
                    <ArrivalFlightIcon />
                  </div>
                  <div className={styles.iatas}>
                    <p>{route.departure.iataCode}</p>
                    <p>{route.arrival.iataCode}</p>
                  </div>
                </div>
                <div className={styles.right}>
                  <img
                    // src={`https://content.airhex.com/content/logos/airlines_${route.carrierCode}_90_30_r.png?proportions=keep`}
                    src={`http://pics.avs.io/100/25/${route?.carrierCode}.png`}
                    alt={route.carrierCode}
                  />
                  <h3>{moment(route.arrival.at).format("HH:mm")}</h3>
                  <p>{route.arrival.iataCode}</p>
                  <p>{moment(route.arrival.at).format("MMMM DD")}</p>
                </div>
              </div>

              {!!layover ? (
                layover === "roundtrip" ? (
                  <Box
                    display="flex"
                    width="90%"
                    height="0"
                    borderTop="1px solid var(--primary-color)"
                    justifyContent="center"
                    alignItems="center"
                    my={2}
                  ></Box>
                ) : (
                  <Box
                    display="flex"
                    width="100%"
                    height={20}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <HistoryToggleOffIcon
                      color="error"
                      style={{
                        fontSize: 16,
                        marginRight: 1,
                      }}
                    />
                    <Typography
                      color="error"
                      fontSize="10px"
                      fontWeight={600}
                      ml={1}
                    >
                      Layover - {layover}
                      &nbsp;
                    </Typography>
                  </Box>
                )
              ) : (
                ""
              )}
            </Box>
          );
        })
      )}
    </div>
  );
};

export default TicketVariant;
